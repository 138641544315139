body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
      Topbar
*/

.topbar {
  padding: 25px;
}

.topbar-logo {
  width: 160px;
}

.topbar-logo-link {
  text-decoration: none;
}

.topbar-subtitle {
  color: #0F9D58;
  position: relative;
  right: 40px;
  top: 8px;
  font-size: 20px;
  font-weight: 300;
}

.topbar-link {
  float: right;
}

/*
      New Ticket Form
*/

.new-ticket-form-cont {
  max-width: 600px;
  margin: auto;
  padding: 15px;
  margin-top: 30px;
}

.new-ticket-form-title {
  text-align: center;
}

.new-ticket-form-field {
  margin-bottom: 15px;
}
